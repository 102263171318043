<template>
 <!-- 发票目录 -->
 <div>
	 <van-cell title="修改手机号" icon="shield-o" is-link :to="{ path: '/phoneUpdate', query: { phone: $route.query.phone } }" />
	 <van-cell title="注销账号" icon="shield-o" is-link :to="{path: '/writeOff' }" />
 </div>
</template>

<script>
import {
	Cell
} from "vant";

export default {
  name: "account",
  components: {
	  [Cell.name]:Cell
  },
  provide() {
    return {};
  },
  data() {
    return {};
  },
  mixins: [],
  watch: {},
  created() {},
  mounted() {},
  methods: {},
  beforeUnmount() {},
  // beforeRouteLeave(to,from,next){},
};
</script>
<style scoped lang="scss">

</style>
